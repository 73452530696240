import {
    $, addAction, doAction, LAYOUTEND, INIT,
} from '@situation/setdesign.util';

let initialized = false;
addAction(INIT, () => {
    const $rows = $('.content-row--mobile-carousel');
    if (!$rows.length) {
        return;
    }

    function updateSlider() {
        if (SetDesign.viewport.width() < 768 && !initialized) {
            initialized = true;
            $rows
                .addClass('owl-carousel')
                .owlCarousel({
                    margin: 25,
                    dots: false,
                    stagePadding: 20,
                    nav: false,
                    navText: ['&lsaquo;', '&rsaquo;'],
                    responsive: { 0: { items: 1 }, 768: { items: 3 } },
                });
        } else if (SetDesign.viewport.width() >= 768 && initialized) {
            initialized = false;
            $rows.removeClass('owl-carousel').trigger('destroy.owl.carousel');
        }
    }

    $rows.on('changed.owl.carousel', (e) => {
        if (e.property.name === 'position') {
            doAction('refreshLazyLoad', $(e.currentTarget));
        }
    });

    updateSlider();
    addAction(LAYOUTEND, updateSlider);
});
